import React from 'react';
import Image, { FixedObject } from 'gatsby-image';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import Text from '../components/atoms/Text';
import Heading from '../components/atoms/Heading';
import ListItem from '../components/atoms/ListItem';
import Box from '../components/atoms/Box';
import { theme } from '../theme';
import Flex from '../components/atoms/Flex';
import LinkButton from '../components/atoms/LinkButton';

type Props = {
  data: {
    markdownRemark: {
      frontmatter: {
        title: string;
        description: string;
        images?: FixedObject[];
        date: string;
        responsibilities: string;
        techStack: string[];
        type: 'web' | 'mobile';
        link?: string;
      };
      excerpt?: string;
      html?: string;
    };
  };
};

const PortfolioPage = ({ data }: Props) => {
  const post = data.markdownRemark;
  const { frontmatter } = post;
  return (
    <Layout>
      <SEO title={frontmatter.title} description={post.frontmatter.description || post.excerpt} />
      <Heading fontSize="4xl">{frontmatter.title}</Heading>
      <Text my={2}>{frontmatter.description}</Text>
      <Text>
        {new Date(frontmatter.date).toLocaleDateString('en-US', {
          month: 'short',
          year: 'numeric',
        })}
      </Text>
      <Flex flexWrap="wrap" mt={12}>
        {frontmatter.images?.map((image: any, i: number) => (
          <Image
            key={i}
            style={{ marginRight: theme.space['8'], marginBottom: theme.space['8'] }}
            fixed={image[frontmatter.type].fixed}
          />
        ))}
      </Flex>
      <Box my={12}>
        <Heading fontSize="lg" as="h3" mb="0">
          My Responsibilities
        </Heading>
        {frontmatter.responsibilities.split(',').map(t => (
          <ListItem key={t}>{t}</ListItem>
        ))}
      </Box>
      <Box my={12}>
        <Heading fontSize="lg" as="h3" mb="0">
          Tech Stack
        </Heading>
        {frontmatter.techStack.map(t => (
          <ListItem key={t}>{t}</ListItem>
        ))}
      </Box>
      {frontmatter.link && (
        <LinkButton my={24} to={frontmatter.link}>
          Check it out!
        </LinkButton>
      )}
      {/* @ts-ignore */}
      <Box mb={24} dangerouslySetInnerHTML={{ __html: post.html }} itemProp="articleBody" />
    </Layout>
  );
};

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        description
        date
        responsibilities
        techStack
        type
        link
        images {
          mobile: childImageSharp {
            fixed(width: 200, height: 433) {
              ...GatsbyImageSharpFixed
            }
          }
          web: childImageSharp {
            fixed(width: 400, height: 255) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`;

export default PortfolioPage;
