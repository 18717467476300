import styled from 'styled-components';
import Text from './Text';

const ListItem = styled(Text)`
  margin: ${props => props.theme.space['2']};
  &:before {
    content: '• ';
    color: ${props => props.theme.colors.green['600']};
  }
`;

export default ListItem;
